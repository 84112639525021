@media (max-width: 1350px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 11px;
  }
}
#content {
  display: flex;
  flex-direction: column;
}
#content section {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
}
#content #contact {
  height: 75vh;
  min-height: 75vh;
  max-height: 75vh;
  justify-content: flex-start;
}
#content #work-experience {
  padding: 5rem 0rem;
  padding: 2.5rem 0rem;
  background-color: rgb(10, 10, 10);
}
#content #skills-section {
  padding: 5rem 0rem;
  padding: 2.5rem 0rem;
  background-color: rgb(10, 10, 10);
}
#content #contact {
  padding: 5rem 0rem;
  padding: 2.5rem 0rem;
  background-color: rgb(10, 10, 10);
}

#home-3d {
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@media (max-width: 700px) {
  #work-experience > div:nth-child(1), #skills-section > div:nth-child(1), #contact > div:nth-child(1) {
    width: 40rem !important;
  }
  #work-experience > div:nth-child(2), #skills-section > div:nth-child(2), #contact > div:nth-child(2) {
    width: 40rem !important;
  }
  .skill-container {
    display: grid;
    height: min-content;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  }
  .skill-container object {
    width: 1.5rem;
  }
}
@media (max-width: 600px) {
  #work-experience > div:nth-child(1), #skills-section > div:nth-child(1), #contact > div:nth-child(1) {
    width: 35rem !important;
  }
  #work-experience > div:nth-child(2), #skills-section > div:nth-child(2), #contact > div:nth-child(2) {
    width: 35rem !important;
  }
}
@media (max-width: 500px) {
  #work-experience > div:nth-child(1), #skills-section > div:nth-child(1), #contact > div:nth-child(1) {
    width: 30rem !important;
  }
  #work-experience > div:nth-child(2), #skills-section > div:nth-child(2), #contact > div:nth-child(2) {
    width: 30rem !important;
  }
}
@media (max-width: 400px) {
  #work-experience > div:nth-child(1), #skills-section > div:nth-child(1), #contact > div:nth-child(1) {
    width: 30rem !important;
  }
  #work-experience > div:nth-child(2), #skills-section > div:nth-child(2), #contact > div:nth-child(2) {
    width: 30rem !important;
  }
}
body {
  background-color: unset !important;
}

#work-experience,
#skills-section,
#contact {
  background-color: transparent !important;
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(6, 56, 39, 0.6), rgba(7, 36, 12, 0.6)), url("../../../public/images/bg-1.jpg") !important;
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  z-index: 2;
}

#light-circle {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.288);
  filter: blur(50px);
  pointer-events: none; /* Makes sure the circle doesn't interfere with other mouse events */
  opacity: 0.05;
  transition: transform 0.1s ease; /* Smooth transition for the movement */
  z-index: 55;
  animation: pulse 5s infinite;
  background-color: black;
  opacity: 0.2;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
html {
  background-color: black;
}

#home {
  position: relative;
  overflow: hidden;
}

.glitch-box {
  position: absolute;
  background: rgba(255, 255, 255, 0.8); /* Light color for visibility */
  opacity: 0; /* Start invisible */
  animation: glitch-animation 5s infinite linear alternate-reverse;
}

.glitch-box {
  position: absolute;
  background: rgba(255, 255, 255, 0.8); /* Initial light color for visibility */
  opacity: 0; /* Start invisible */
  animation: glitch-animation 1s infinite linear alternate-reverse;
}

@keyframes glitch-animation {
  0%, 100% {
    opacity: 0;
    transform: translate(0);
  }
  10% {
    opacity: 1;
    background: rgba(255, 0, 0, 0.8); /* Red */
    transform: translate(-5px, 5px) scale(1.1);
  }
  20% {
    background: rgba(0, 255, 0, 0.8); /* Green */
    transform: translate(5px, -5px) scale(0.9);
  }
  30% {
    background: rgba(0, 0, 255, 0.8); /* Blue */
    transform: translate(-5px, -5px);
  }
  40% {
    background: rgba(255, 255, 0, 0.8); /* Yellow */
    transform: translate(5px, 5px);
  }
  50% {
    background: rgba(0, 255, 255, 0.8); /* Cyan */
    transform: translate(-5px, 5px) scale(1.2);
  }
  60% {
    background: rgba(255, 0, 255, 0.8); /* Magenta */
    transform: translate(5px, -5px) scale(0.8);
  }
  70% {
    background: rgba(255, 255, 255, 0.8); /* White */
    transform: translate(-5px, -5px);
  }
  80% {
    background: rgba(0, 0, 0, 0.8); /* Black */
    transform: translate(5px, 5px);
  }
  90% {
    background: rgba(255, 255, 255, 0.8); /* Back to White */
    transform: translate(0) scale(1);
  }
}
/* Add this to your CSS file */
.home-slide-left {
  animation: slideOff 1s forwards; /* Adjust time as needed */
}

@keyframes slideOff {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/* Add this to your CSS file */
.scene-3d-slide-top {
  animation: slideOffTop 0.5s forwards; /* Adjust time as needed */
}

@keyframes slideOffTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
/* Add this to your CSS file */
.slide-up {
  animation: slideUp 0.5s forwards; /* Adjust time as needed */
}

@keyframes slideUp {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
#home {
  transition: max-height 1s ease, margin-top 0.5s ease; /* Adjust time and easing as needed */
  transition: min-height 1s ease, margin-top 0.5s ease; /* Adjust time and easing as needed */
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

/* This class will prevent scrolling */
.no-scroll {
  overflow-y: hidden;
}

html ::-webkit-scrollbar {
  background-color: rgba(0, 141, 115, 0.1) !important;
}
html ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 255, 209, 0.5) !important;
}

.home-slide-right {
  animation: slideOff-back 1s forwards; /* Adjust time as needed */
}

@keyframes slideOff-back {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
/* Add this to your CSS file */
.scene-3d-slide-bottom {
  animation: slideOffTop-back 0.5s forwards; /* Adjust time as needed */
}

@keyframes slideOffTop-back {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
#home-3d {
  z-index: 4;
}

.back-button-slide-left {
  animation: back-button-slide-left-animation 1s forwards;
}

@keyframes back-button-slide-left-animation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 2rem));
  }
}
.back-button-slide-right {
  animation: back-button-slide-right-animation 1s forwards;
}

@keyframes back-button-slide-right-animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
#skills-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-self: center;
}
#skills-section > div:nth-child(2) {
  width: 50rem;
}
#skills-section h1 {
  color: white;
  padding-top: 0rem;
  margin-top: 0rem;
}

.about-me-title {
  display: flex;
  user-select: none;
  align-items: center;
  width: 50rem;
}
.about-me-title > div:first-child {
  background-color: white;
  height: 2px;
  flex: 0.4;
  background-color: rgba(255, 255, 255, 0.2);
}
.about-me-title p {
  font-family: "Roboto";
  font-size: 1.7188rem;
  font-family: "Playfair Display";
  width: max-content;
  padding: 0rem 2rem;
  color: rgba(255, 255, 255, 0.9);
}
.about-me-title > div:last-child {
  background-color: white;
  height: 2px;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

.about-me-container {
  display: flex;
  flex-direction: column;
}
.about-me-container > p:nth-child(1) {
  font-family: "Roboto";
  font-size: 1.7188rem;
  font-family: "Playfair Display";
  color: white;
  width: max-content;
  margin-top: 3rem;
}
.about-me-container > p:nth-child(2) {
  font-family: "Roboto";
  font-size: 1.7188rem;
  font-family: "Playfair Display";
  color: white;
  width: max-content;
  margin-top: 0.5rem;
}
.about-me-container > p:nth-child(3) {
  font-family: "Roboto";
  font-size: 1.25rem;
  font-family: "Playfair Display";
  color: white;
  line-height: 1.75;
  font-weight: 500;
  text-align: left;
  margin-top: 1.5rem;
}

.skills-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 3rem;
  max-width: 50rem;
  align-self: flex-start;
}
.skills-container > p:nth-child(1) {
  font-family: "Roboto";
  font-size: 1.7188rem;
  font-family: "Playfair Display";
  color: white;
  justify-self: flex-start;
}

.skills-table {
  width: 100%;
}
.skills-table .skills-column {
  display: flex;
}
.skills-table .skills-column > p {
  padding-top: 2.5rem;
  font-family: "Roboto";
  font-size: 1.25rem;
  font-family: "Playfair Display";
  color: white;
  text-align: center;
  border-bottom: 3px solid rgba(0, 255, 209, 0.2);
  flex: 1;
  padding-bottom: 0.75rem;
  user-select: none;
}
.skills-table .skill-container {
  margin-top: 2.5rem;
}
.skills-table > div {
  display: flex;
  flex-wrap: wrap;
  row-gap: 3rem;
}
.skills-table > div p {
  font-family: "Roboto";
  font-size: 1.25rem;
  font-family: "Playfair Display";
  color: white;
  padding: 0rem 1rem;
  justify-self: flex-start;
}

.skill-container {
  display: grid;
  height: min-content;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
  column-gap: 1.5rem;
}
.skill-container img {
  width: 2rem;
}

.skills-content {
  display: flex;
}

.active-skill-category {
  padding-bottom: 10px;
  border-bottom: 3px solid red;
}

.skill-category-active {
  border-bottom: 3px solid #00ffd1 !important;
}